<template>
  <div>
    <div style="text-align: center">
      <el-tag>光面爆破上报时间统计</el-tag>
    </div>
    <div>
      <div style="margin-bottom: 10px">
        <el-date-picker
            v-model="shortcensustime"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="seleque">查询</el-button>
      </div>
    </div>
    <div style="margin-bottom: 50px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="small"
          highlight-hover-row>
        <vxe-column field="bdstname" title="标段" width="360px"></vxe-column>
        <vxe-column field="operation" title="工点" width="360px"></vxe-column>
        <vxe-column field="uptime" title="上报时间" width="480px">
          <template #default="scope">
            <el-tag v-if="new Date(scope.row.uptime).getTime() > this.deadline" type="danger" effect="dark" size="mini">
              {{ scope.row.uptime }}
            </el-tag>
            <el-tag v-if="new Date(scope.row.uptime).getTime() <= this.deadline" type="gjtime" effect="dark" size="mini">
              {{ scope.row.uptime }}
            </el-tag>
          </template>
        </vxe-column>
        <template #empty>
            <span style="color: red;">
              <img src="../../../assets/img1.gif">
              <p>没有更多数据了！</p>
            </span>
        </template>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";

export default {
  name: "shortcensus",
  data() {
    return {
      shortcensustime: '',
      tableData: [],
      allAlign: 'center',
      deadline: ''
    }
  },
  created() {
    this.shortcensustime = setNowDate(new Date());
    this.getdate(this.shortcensustime);
  },
  methods:{
    getdate(val){
      console.log(val)
      let deadlineStr = val + " " + "19:40:00";
      this.deadline = Date.parse(deadlineStr);
      this.axios.post("/czbeton/centime", (response) => {
        console.log(response)
        this.tableData = response.obj
      },{
        time: val
      })
    },
    seleque(){
      this.getdate(this.shortcensustime)
    }
  }
}
</script>

<style scoped>
.el-tag--gjtime{
  background: #00bfc1;
  border-style: hidden;
  color: white;
}
</style>