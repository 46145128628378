<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">统计上报时间</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="margin: 0 auto;width: 95%;background-color: white;padding-top: 30px;min-height: 500px">
      <el-tabs v-model="activeName"  tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="隧道工效" name="first" style="margin-left: 50px"><Sdgxcensus></Sdgxcensus></el-tab-pane>
        <el-tab-pane label="初支混凝土" name="second" style="margin-left: 50px"><Shortcensus></Shortcensus></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Sdgxcensus from "@/views/procoll/census/sdgxcensus"
import Header from "@/views/header";
import Shortcensus from "@/views/procoll/census/shortcensus"
export default {
  name: "census",
  components: {Sdgxcensus, Header, Shortcensus},
  data(){
    return{
      activeName: 'first'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  },
  methods:{
    handleClick(){

    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>
