<template>
  <div>
    <div style="text-align: center">
      <el-tag>隧道工效上报时间统计</el-tag>
    </div>
    <div>
      <div style="margin-bottom: 10px">
        <el-date-picker
            v-model="value2"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="seleque">查询</el-button>
        <div style="float: right;margin-right: 100px">
          <el-date-picker
              v-model="value3"
              type="month"
              format="YYYY/MM"
              value-format="YYYY-MM"
              placeholder="选择月份"
              size="small">
          </el-date-picker>
          <el-button size="small" type="danger" @click="getallmoth">统计每月</el-button>
        </div>
      </div>

    </div>
    <div v-if="init" style="margin-bottom: 50px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="small"
          highlight-hover-row>
        <vxe-column field="proname" title="工点" width="360px"></vxe-column>
        <vxe-column field="username" title="上报人员" width="400px"></vxe-column>
        <vxe-column field="time" title="上报时间" width="480px">
          <template #default="scope">
            <el-tag v-if="new Date(scope.row.time).getTime() > this.deadline" type="danger" effect="dark" size="mini">{{ scope.row.time }}</el-tag>
            <el-tag v-if="new Date(scope.row.time).getTime() <= this.deadline" type="gjtime" effect="dark" size="mini">{{ scope.row.time }}</el-tag>
          </template>
        </vxe-column>
        <template #empty>
            <span style="color: red;">
              <img src="../../../assets/img1.gif">
              <p>没有更多数据了！</p>
            </span>
        </template>
      </vxe-table>
    </div>
    <div v-if="!init">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData1"
          size="small"
          highlight-hover-row>
        <vxe-column field="proname" title="工点" width="200"></vxe-column>
        <vxe-column field="nomol" title="未超时" width="90"></vxe-column>
        <vxe-column field="overtime" title="超时" width="90"></vxe-column>
        <template #empty>
            <span style="color: red;">
              <img src="../../../assets/img1.gif">
              <p>没有更多数据了！</p>
            </span>
        </template>
      </vxe-table>
      <vxe-pager
          :current-page="tablePage1.currentPage"
          :page-size="tablePage1.pageSize"
          :total="tablePage1.totalResult"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="handlePageChange1">
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";
import { ElMessage } from 'element-plus'
export default {
  name: "sdgxcensus",
  data(){
    return{
      tableData: [],
      tableData1: [],
      allAlign: 'center',
      value2: '',
      value3: '',
      init: true,
      tablePage1: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0
      },
      deadline: ''
    }
  },
  created() {
    let date = setNowDate(new Date());
    this.value2 = date;
    this.getdate(date);
  },
  methods:{
    getdate(val){
      this.init = true;
      let deadlineStr = val + " " + "19:40:00";
      this.deadline = Date.parse(deadlineStr);
      this.axios.post("/other/seldateuser", (response) => {
        this.tableData = response.obj
      },{
        bbname: '隧道工效',
        date: val
      })
    },
    seleque(){
      this.getdate(this.value2);
    },
    getallmoth(){
      if (this.value3 !== ''){
        this.init = false;
        this.axios.post("/other/selmouuser", (response) => {
          let list = response.obj;
          this.tablePage1.totalResult = list.length
          this.tableData1 = list.slice((this.tablePage1.currentPage - 1) * this.tablePage1.pageSize, this.tablePage1.currentPage * this.tablePage1.pageSize)
        },{
          bbname: '隧道工效',
          date: this.value3
        })
      }else {
        ElMessage({
          message: '请选择月份',
          type: 'warning',
        })
      }
    },
    handlePageChange1 ({ currentPage, pageSize }) {
      this.tablePage1.currentPage = currentPage
      this.tablePage1.pageSize = pageSize
      this.getallmoth()
    }
  }
}
</script>

<style scoped>
.el-tag--gjtime{
  background: #00bfc1;
  border-style: hidden;
  color: white;
}
</style>
